import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c752246e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-mb-xs" }
const _hoisted_2 = { class: "q-mb-xs" }
const _hoisted_3 = { class: "absolute-full flex flex-center" }
const _hoisted_4 = { class: "curso-nome" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerCurso = _resolveComponent("VerCurso")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VerCurso, { ref: "modal" }, null, 512),
    (_ctx.cursos)
      ? (_openBlock(), _createBlock(_component_q_page, {
          key: 0,
          class: "row"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cursos, (curso, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: curso.id,
                class: "col-xs-12 col-sm-12 col-md-6 q-pa-md"
              }, [
                _createVNode(_component_q_card, { class: "my-card" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, { horizontal: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_img, {
                          "spinner-color": "primary",
                          class: "col-5",
                          src: `${_ctx.urlImg}${curso.imagem}`
                        }, null, 8, ["src"]),
                        _createVNode(_component_q_card_section, {
                          class: "q-pa-none",
                          style: {"width":"100%"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(curso.resumo), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_q_separator),
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, { class: "q-mb-md text-gray curso-items" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_1, [
                                      _createVNode(_component_q_icon, {
                                        color: "grey",
                                        name: "auto_stories"
                                      }),
                                      _createTextVNode(" " + _toDisplayString(curso.disciplinas.length == 1
                      ? "1 disciplina"
                      : `${curso.disciplinas.length} disciplinas`), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_2, [
                                      _createVNode(_component_q_icon, {
                                        color: "grey",
                                        name: "style"
                                      }),
                                      _createTextVNode(" " + _toDisplayString(_ctx.contarAssuntos(curso)), 1)
                                    ]),
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_q_icon, {
                                        color: "grey",
                                        name: "play_circle"
                                      }),
                                      _createTextVNode(" " + _toDisplayString(_ctx.contarAulas(curso)), 1)
                                    ])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_q_linear_progress, {
                                  rounded: "",
                                  size: "25px",
                                  value: _ctx.porcetangem(curso, index)[0],
                                  color: "green"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_3, [
                                      _createVNode(_component_q_badge, {
                                        color: "white",
                                        "text-color": "green",
                                        label: _ctx.aulasAssistida[index] + '/' + _ctx.aulasTotal[index]
                                      }, null, 8, ["label"])
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["value"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_separator),
                    _createVNode(_component_q_card_actions, { align: "between" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(curso.descricao), 1),
                        _createVNode(_component_q_btn, {
                          flat: "",
                          color: "primary",
                          icon: "play_circle",
                          label: "Ver",
                          onClick: ($event: any) => (_ctx.openModal(curso))
                        }, null, 8, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_q_inner_loading, {
          key: 1,
          showing: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_spinner_facebook, {
              size: "30px",
              color: "primary",
              class: "q-ma-sm"
            }),
            _createTextVNode(" Carregando... ")
          ]),
          _: 1
        }, 8, ["showing"]))
  ], 64))
}